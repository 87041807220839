
/*
******* NOTICE **********
This is an auto generated file. Please DO NOT modify !!
if you want to add any new config, please have a look at aws-init.ts
*************************
*/
import { ResourcesConfig } from 'aws-amplify';

export interface AwsConfig {
  clientApp: ResourcesConfig;
  adminApp: ResourcesConfig;
  familyApp: ResourcesConfig;
  tvApp: ResourcesConfig;

  rootDomain: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  authenticationFlowType?: string;
  aws_user_files_s3_bucket_region: string;
  certificates: {
    app: string;
    admin: string;
    family: string;
    api: string;
    tv: string;
  }
  kmsKeyId: string;
  databases?: {
    [key: string]: {
      latestStreamArn: string;
    }
  };
  familyAppUrl: string;
  tvAppUrl: string;
  apolloAppUrl: string;
  adminAppUrl: string;
};

    

    export const awsConfig: AwsConfig = {
  adminApp: {
    API: {
      GraphQL: {
        endpoint: 'https://admin.api.dev.resparke.com/graphql',
        region: 'ap-southeast-2',
        defaultAuthMode: 'userPool'
      }
    },
    Auth: {
      Cognito: {
        userPoolId: 'ap-southeast-2_DNdze52DS',
        userPoolClientId: '2uqf0lsjulqj0mnerj8kkvgbdf',
        identityPoolId: 'ap-southeast-2:76e53d2d-c413-45d4-bbdc-e1facaec2c82',
        allowGuestAccess: true
      }
    }
  },
  clientApp: {
    API: {
      GraphQL: {
        endpoint: 'https://client.api.dev.resparke.com/graphql',
        region: 'ap-southeast-2',
        defaultAuthMode: 'userPool'
      }
    },
    Auth: {
      Cognito: {
        userPoolId: 'ap-southeast-2_DNdze52DS',
        userPoolClientId: '2uqf0lsjulqj0mnerj8kkvgbdf',
        identityPoolId: 'ap-southeast-2:76e53d2d-c413-45d4-bbdc-e1facaec2c82',
        allowGuestAccess: true
      }
    }
  },
  familyApp: {
    API: {
      GraphQL: {
        endpoint: 'https://family.api.dev.resparke.com/graphql',
        region: 'ap-southeast-2',
        defaultAuthMode: 'userPool'
      }
    },
    Auth: {
      Cognito: {
        userPoolId: 'ap-southeast-2_DNdze52DS',
        userPoolClientId: '2uqf0lsjulqj0mnerj8kkvgbdf',
        identityPoolId: 'ap-southeast-2:76e53d2d-c413-45d4-bbdc-e1facaec2c82',
        allowGuestAccess: true
      }
    }
  },
  tvApp: {
    API: {
      GraphQL: {
        endpoint: '',
        region: 'ap-southeast-2',
        defaultAuthMode: 'userPool'
      }
    },
    Auth: {
      Cognito: {
        userPoolId: 'ap-southeast-2_DNdze52DS',
        userPoolClientId: '2uqf0lsjulqj0mnerj8kkvgbdf',
        identityPoolId: 'ap-southeast-2:76e53d2d-c413-45d4-bbdc-e1facaec2c82',
        allowGuestAccess: true
      }
    }
  },
  region: 'ap-southeast-2',
  userPoolId: 'ap-southeast-2_DNdze52DS',
  userPoolWebClientId: '2uqf0lsjulqj0mnerj8kkvgbdf',
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
  apolloAppUrl: 'https://app.dev.resparke.com',
  familyAppUrl: 'https://family.dev.resparke.com',
  tvAppUrl: 'https://tv.dev.resparke.com',
  adminAppUrl: 'https://admin.dev.resparke.com',
  kmsKeyId: 'ade59ed4-a354-42da-b46d-f284e7f56a85',
  certificates: {
    admin: 'arn:aws:acm:us-east-1:517490064795:certificate/4885f832-7160-4278-9128-537dba263b33',
    family: 'arn:aws:acm:us-east-1:517490064795:certificate/3880f70e-fc63-4d29-b7d4-ad11dc23a454',
    app: 'arn:aws:acm:us-east-1:517490064795:certificate/a8f8cd66-1cff-452c-8550-05489efb5957',
    api: 'arn:aws:acm:us-east-1:517490064795:certificate/ef048a74-85ff-4951-b87d-0516c89442f3',
    tv: 'arn:aws:acm:us-east-1:517490064795:certificate/431fb1a2-4db8-4ffa-8ea3-74c31be8934a'
  },
  databases: {
    'apollo-accessTokens': { latestStreamArn: '' },
    'apollo-artists': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-artists/stream/2024-03-28T03:32:23.310'
    },
    'apollo-categories2': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-categories2/stream/2023-11-22T09:32:27.991'
    },
    'apollo-events': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-events/stream/2023-12-31T09:51:21.465'
    },
    'apollo-facilities': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-facilities/stream/2022-11-23T07:58:15.559'
    },
    'apollo-logs': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-logs/stream/2024-08-08T06:54:34.542'
    },
    'apollo-media': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-media/stream/2023-11-22T09:32:27.691'
    },
    'apollo-migrations': { latestStreamArn: '' },
    'apollo-organisations': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-organisations/stream/2023-01-06T14:17:16.520'
    },
    'apollo-residents': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-residents/stream/2023-03-08T10:18:25.327'
    },
    'apollo-search': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-search/stream/2024-05-03T01:58:25.863'
    },
    'apollo-search-aggregation': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-search-aggregation/stream/2024-03-13T01:10:20.015'
    },
    'apollo-streamLogs': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-streamLogs/stream/2023-02-10T03:29:48.977'
    },
    'apollo-tags': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-tags/stream/2024-03-26T09:23:55.942'
    },
    'apollo-uploads': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-uploads/stream/2024-10-23T01:25:52.606'
    },
    'apollo-userSessions': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-userSessions/stream/2023-02-21T09:31:18.030'
    },
    'apollo-users': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-users/stream/2022-11-23T07:58:15.539'
    },
    'apollo-videoLogs': {
      latestStreamArn: 'arn:aws:dynamodb:ap-southeast-2:517490064795:table/apollo-videoLogs/stream/2024-02-19T07:37:19.211'
    }
  },
  rootDomain: 'dev.resparke.com'
};

  